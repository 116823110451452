import React from "react"
import Layout from "../../../Components/pageComponents/contentContainer"
import SolutionInfoSection from "../../../Components/pageComponents/solutionsInfoSection"

export const query = graphql`
    query {
        banner: file(relativePath: { eq: "products/networking/wan-acceleration-or-optimization/WAN Acceleration or Optimization.png" }) {
            childImageSharp {
                fluid(maxWidth: 2000) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        stock: file(relativePath: { eq: "products/networking/wan-acceleration-or-optimization/WAN Optimization and Acceleration Stock.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 2000) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
    }
`

export default ({ data }) => {
    return (
        <Layout title="WAN Acceleration or Optimization" image={data.banner.childImageSharp.fluid}>
            <SolutionInfoSection heading="Products We Offer" title="WAN Acceleration / Optimization" description="WAN Acceleration / Optimization is used to improve the accuracy of
                            the flow of Data across the Wide Area Network to and from the
                            Centralized Data Center to other remote locations. By Accelerating /
                            Optimizing the WAN, users can now access data and information much quicker.
                            WAN Acceleration / Optimization helps:" img={data.stock.childImageSharp.fluid}>
                <ul className="mt-3">
                    <li>Fix Network Latency</li>
                    <li>Minimize Packet Loss</li>
                    <li>Mitigate Limitations</li>
                </ul>
            </SolutionInfoSection>

        </Layout>
    )
}